import React, { createContext, useCallback, useEffect, useState, type ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { api } from '../services/api';

interface EventResponse {
  id: string;
  title: string;
  description: string;
  latitude: string;
  longitude: string;
  scheduleDate: Date;
  location: string;
  startTime: Date;
  endTime: Date;
  imageURL: string;
  categoryId: string;
  deleted_at: Date | null;
  deleted: false;
  createdAt: Date;
  updatedAt: Date;
}

interface EventInput {
  title: string;
  description: string;
  latitude: string;
  longitude: string;
  location: string;
  startTime: Date;
  categoryId: number;
  file: File;
  endTime: Date;
  scheduleDate: Date;
}

export interface EventContextData {
  eventList: EventResponse[] | null;
  loading: boolean;
  getAll: (currentYear: string) => Promise<void>;
  create: (event: EventInput) => Promise<void>;
  deleteEvent: (id: number) => Promise<void>;
  edit: (event: EventInput, id: number) => Promise<void>;
  currentYear: string;
  setCurrentYear: (value: string) => void;
}

interface EventContextProviderProps {
  children: ReactNode;
}

interface Data {
  title: string;
  description: string;
  latitude: string;
  longitude: string;
  location: string;
  startTime: Date;
  categoryId: number;
  file: File;
  endTime: Date;
  scheduleDate: Date;
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const EventContext = createContext({} as EventContextData);

export function EventContextProvider(props: EventContextProviderProps): JSX.Element {
  const [currentYear, setCurrentYear] = useState('2025');
  const history = useHistory();
  const [eventList, setEventList] = useState<EventResponse[] | null>(null);
  const [loading, setLoading] = useState(true);

  const create = useCallback(async (data: Data) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('title', data.title.toString());

      formData.append('description', data.description);
      formData.append('latitude', data.latitude);
      formData.append('longitude', data.longitude);
      formData.append('location', data.location);
      formData.append('startTime', data.startTime.toISOString());
      formData.append('endTime', data.endTime.toISOString());
      formData.append('categoryId', JSON.stringify(data.categoryId));
      formData.append('scheduleDate', data.scheduleDate.toISOString());
      if (data?.file) {
        formData.append('file', data.file);
      }

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      const schedule = await api.post('schedules', formData, config);
      await getAll(currentYear);
      toast('Evento cadastrado com sucesso!', { type: 'success' });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);

      toast('Não foi possível cadastrar o evento', { type: 'error' });
      await Promise.reject(new Error('fail'));
    }
  }, []);

  const getAll = useCallback(async (currentYear: string) => {
    setLoading(true);
    try {
      const response = await api.get('schedules', {
        params: { page: 1, perPage: 290, paginated: true, year: currentYear },
      });

      setEventList(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, []);

  const deleteEvent = useCallback(async (id: number) => {
    setLoading(true);
    try {
      const response = await api.delete(`schedules/${id}`);

      await getAll(currentYear);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, []);

  const edit = useCallback(async (data: Data, id: number) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('title', data.title.toString());

      formData.append('description', data.description);
      formData.append('latitude', data.latitude);
      formData.append('longitude', data.longitude);
      formData.append('location', data.location);
      formData.append('startTime', data.startTime.toISOString());
      formData.append('endTime', data.endTime.toISOString());
      formData.append('categoryId', JSON.stringify(data.categoryId));
      formData.append('scheduleDate', data.scheduleDate.toISOString());
      if (data?.file) {
        formData.append('file', data.file);
      }

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      const response = await api.patch(`schedules/${id}`, formData, config);

      await getAll(currentYear);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      await Promise.reject(new Error('fail'));
    }
  }, []);

  return (
    <EventContext.Provider
      value={{
        eventList,
        loading,
        edit,
        deleteEvent,
        create,
        getAll,
        currentYear,
        setCurrentYear,
      }}
    >
      <>
        <ToastContainer />
        {props.children}
      </>
    </EventContext.Provider>
  );
}
