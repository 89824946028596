import styled from 'styled-components';

export const NavBarContainer = styled.nav`
  background-color: #005675;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  padding: 20px;

  height: 100px;

  .containerd {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
  }

  .brasao {
    margin-left: 0.5rem;
    font-size: 1.6rem;
  }

  .user {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .userMobile {
    display: none;
  }

  /*
  .nav-menu{
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width:70vw;
    justify-content: end;
    margin-right: 2rem;
  }

  .nav-links{
    color:white;
    text-decoration:none;
    padding: 0.5rem 1rem;

  }

  .nav-links:hover{
    background-color:#5ea9c1;
    border-radius:4px;
    transition: all 0.2s ease-out;
    cursor: pointer;

  }

  .fa-bars {
    color:#fff;
  }

  .menu-icon{
    display: none;
  } */

  @media (max-width: 1024px) {
    .user {
      display: none;
    }

    .userMobile {
      display: flex;
      flex-direction: row;
    }
    .title {
      display: none;
    }
    .icon {
      width: 30px;
    }

    .name-user {
      display: none;
      font-size: 25px;
    }

    .logout {
      font-size: 20px;
    }
  }
`;
