import React from 'react';
import { Input } from 'reactstrap';

interface PropsType {
  currentYear: string;
  setCurrentYear: (value: string) => void;
}

export function FilterYear({ currentYear, setCurrentYear }: PropsType): JSX.Element {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          border: '1px solid #ececec',
          alignItems: 'center',
          backgroundColor: '#fff',
          padding: 5,
          borderRadius: 8,
          boxShadow: ' 0px 5px 5px #222D3E',
        }}
      >
        <div>
          <span style={{ fontWeight: 'bold', color: '#1B3A4D', marginLeft: 3 }}>
            Edições Expofeira
          </span>
        </div>
        <div>
          <Input
            type="select"
            onChange={(e) => {
              setCurrentYear(e.target.value);
            }}
            style={{ width: 90, marginRight: 8, marginLeft: 8 }}
            value={currentYear}
          >
            <option key={'10'} value={'2025'}>
              2025
            </option>
            <option key={'10'} value={'2024'}>
              2024
            </option>
            <option key={'20'} value={'2023'}>
              2023
            </option>
          </Input>
        </div>
      </div>
    </div>
  );
}
