import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  FormGroup,
  Label,
  Spinner,
} from 'reactstrap';
import { api } from '../../../services/api';
import { DatePicker } from 'reactstrap-date-picker';
import { toast } from 'react-toastify';
import { useAuth } from './../../../hooks/useAuth';
import LocationPicker from 'react-leaflet-location-picker';
import moment from 'moment';
import { MapContainer } from 'react-leaflet';
import { Map } from './../map';

interface User {
  id: string;
  name: string;
  phone: string;
  profile: string;
  cpf: string;
}

interface Ticket {
  id: string;
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  status: string;
  quantity: string;
  latitude: string;
  longitude: string;
  imageURL: string;
  user: User;
}

interface PropsEvent {
  isOpen: boolean;
  handleIsOpen: () => void;
  ticket: Ticket;
  getAllUsers: (page: number) => Promise<void>;
}

export function ModalEditTicket({
  isOpen,
  handleIsOpen,
  ticket,
  getAllUsers,
}: PropsEvent): JSX.Element {
  const [status, setStatus] = useState('');
  const [name, setName] = useState('');
  const [quantity, setQuantity] = useState('');
  const [cpf, setCpf] = useState('');
  const [number, setNumber] = useState('');
  const [nameTicket, setNameTicket] = useState('');
  const [description, setDescription] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false);
  // errors
  const [errorStatus, setErrorStatus] = useState('');

  const pointVals = [[ticket.latitude, ticket.longitude]];
  const pointMode = {
    banner: false,
    control: {
      values: pointVals,
      onClick: (point) => {},
      onRemove: (point) => {
        console.log("I've just been clicked for removal :(", point);
      },
    },
  };
  const teste = {
    center: [ticket.latitude, ticket.longitude] as unknown as [number, number],
    zoom: 16,
  };

  useEffect(() => {
    setCpf(ticket.user.cpf);
    setName(ticket.user.name);
    setNumber(ticket.user.phone);
    setNameTicket(ticket.name);
    setDescription(ticket.description);
    setImageURL(ticket.imageURL);
    setStartDate(ticket.startDate);
    setEndDate(ticket.endDate);
    setStatus(ticket.status);
    setQuantity(ticket.quantity);
  }, [ticket, isOpen]);

  async function updateTicketStatus(): Promise<void> {
    if (status.length < 0 || status === 'default') {
      setErrorStatus('Campo obrigatório.');
    } else {
      setErrorStatus('');
    }

    if (status.length > 1 && status !== 'default') {
      setLoading(true);

      try {
        await api.patch(`ticket/${ticket?.id}`, {
          status,
        });
        close();
        toast('Cupom atualizado com sucesso!', { type: 'success' });
        await getAllUsers(1);

        setLoading(false);
      } catch (error) {
        toast('Não foi possivel atualizar o cupom', { type: 'error' });
        setLoading(false);
      }
    }
  }

  function close(): void {
    setErrorStatus('');
    setCpf('');
    setName('');
    setStatus('');
    handleIsOpen();
  }
  function handleState(point): void {
    setLatitude(point.lat);
    setLongitude(point.lng);
  }

  function isValidLatLng(lat, lng): boolean {
    return (
      typeof lat === 'number' &&
      typeof lng === 'number' &&
      lat >= -90 &&
      lat <= 90 &&
      lng >= -180 &&
      lng <= 180
    );
  }

  return (
    <div>
      <Modal isOpen={isOpen} toggle={close} size={'lg'}>
        <ModalHeader toggle={close}>Editar cupom</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="exampleEmail">Imagem</Label>
              {imageURL?.length > 1 && (
                <div style={{ display: 'flex', justifyContent: 'center', maxHeight: 500 }}>
                  <img src={`${imageURL}`} alt={'cupom'} width={'50%'} height={'auto'} />
                </div>
              )}
            </FormGroup>
            {/* <FormGroup>
              <Label for="exampleEmail">Nome do usuário</Label>
              <Input value={name} disabled />
            </FormGroup> */}
            <div className="row">
              <div className="col">
                <FormGroup>
                  <Label>Nome do usuário</Label>
                  <Input value={name} disabled />
                </FormGroup>
              </div>
              <div className="col">
                <FormGroup>
                  <Label>Quantidade de cupons</Label>
                  <Input disabled value={quantity} />
                </FormGroup>
              </div>
            </div>
            <FormGroup>
              <Label for="exampleEmail">CPF do usuário</Label>
              <Input value={cpf} disabled />
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">Número para contato do usuário</Label>
              <Input value={number} disabled />
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">Nome do cupom</Label>
              <Input value={nameTicket} disabled />
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">Descrição do cupom</Label>
              <Input type="textarea" value={description} disabled />
            </FormGroup>
            <div className="row">
              <div className="col">
                <FormGroup>
                  <Label>Data início</Label>
                  <DatePicker
                    name="firstName"
                    disabled
                    showClearButton={false}
                    type="date"
                    value={startDate}
                  />
                </FormGroup>
              </div>
              <div className="col">
                <FormGroup>
                  <Label>Data fim</Label>
                  <DatePicker
                    name="firstName"
                    disabled
                    showClearButton={false}
                    type="date"
                    value={endDate}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <FormGroup>
                  <Label>Hora início</Label>
                  <Input value={moment(startDate).format('HH[h]:mm[min]')} disabled />
                </FormGroup>
              </div>
              <div className="col">
                <FormGroup>
                  <Label>Hora fim</Label>
                  <Input value={moment(endDate).format('HH[h]:mm[min]')} disabled />
                </FormGroup>
              </div>
            </div>
            <FormGroup>
              <Label for="exampleEmail">Mapa</Label>
              {/* <LocationPicker
                showInputs={false}
                pointMode={pointMode}
                showControls={false}
                startPort={teste}
              /> */}

              {isValidLatLng(ticket?.latitude, ticket?.longitude) && (
                <MapContainer
                  // center={[38, 139.69222]}
                  // zoom={6}
                  // minZoom={3}
                  // maxZoom={19}
                  // maxBounds={[
                  //   [-85.06, -180],
                  //   [85.06, 180],
                  // ]}
                  // scrollWheelZoom={true}#2C2C2D
                  bounds={[
                    [-0.042373, -51.11142],
                    [-0.042287, -51.103384],
                  ]}
                  style={{ width: '100%', height: 320 }}
                >
                  {ticket?.latitude && ticket?.longitude && (
                    <Map lat={ticket?.latitude} lng={ticket?.longitude} />
                  )}
                  {/* <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" /> */}
                </MapContainer>
              )}
            </FormGroup>
            <FormGroup>
              <Label
                style={{
                  color: errorStatus?.length > 1 ? '#E44B39' : '#2C2C2D',
                }}
                for="exampleEmail"
              >
                Status
              </Label>
              <Input
                style={{ borderColor: errorStatus?.length > 1 ? '#E44B39' : '#DEE2E6' }}
                type="select"
                name="select"
                id="exampleSelect"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
              >
                <option key={'default'} value={'default'}>
                  Selecione o status
                </option>
                <option key={'pendent'} value={'Pendente'}>
                  Pendente
                </option>
                <option key={'active'} value={'Aprovado'}>
                  Aprovado
                </option>
                <option key={'active'} value={'Negado'}>
                  Negado
                </option>
              </Input>

              <text
                style={{
                  fontSize: 14,
                  color: '#E44B39',
                  marginBottom: 5,
                }}
              >
                {errorStatus}
              </text>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={async () => {
              await updateTicketStatus();
            }}
            style={{ minWidth: 120 }}
            disabled={loading}
          >
            {loading ? <Spinner type="border" size={'sm'} color="light" /> : 'Atualizar status'}
          </Button>{' '}
          <Button color="secondary" onClick={close}>
            Cancelar
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </div>
  );
}
