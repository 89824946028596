import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import {
  FormGroup,
  Form,
  Input,
  Label,
  Button,
  Spinner,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { Container } from './style';
import { useAuth } from './../../hooks/useAuth';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

export const Login = (): JSX.Element => {
  const [cpf, setCPF] = useState('');
  const [password, setPassword] = useState('');
  const [errorCPF, setErrorCPF] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const { signIn, loading } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  // const { signIn, loading } = useAuth();
  async function handleSubmitLogin(): Promise<void> {
    if (cpf?.length !== 14 || cpf === null) {
      setErrorCPF('CPF obrigatório.');
    } else {
      setErrorCPF('');
    }
    if (password === '' || password === null) {
      setErrorPassword('Senha obrigatória.');
      // } else if (password.length < 8) {
      //   setErrorPassword('Mínimo 8 caracteres.');
    } else {
      setErrorPassword('');
    }

    // if (cpf.length === 14 && password !== '' && password.length >= 8) {
    if (cpf?.length === 14 && password !== '' && password !== null) {
      // localStorage.setItem('inputValueCpf', cpf);
      // localStorage.setItem('inputValuePassword', password);
      await signIn({ cpf, password });
    }
  }
  // useEffect(() => {
  //   setCPF(localStorage.getItem('inputValueCpf') as unknown as string);
  //   setPassword(localStorage.getItem('inputValuePassword') as unknown as string);
  // }, []);

  function cpfMask(value: string): string {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }

  return (
    <div
      className="container"
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: '100vh',
        // width: '100%',
      }}
    >
      <div
        className="mx-auto d-none d-md-block"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '50%',
        }}
      >
        <img src="/assets/IMAGE.png" alt="icone-amapa" width={'100%'} height={'auto'} />
      </div>
      <div
        className="col-sm"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          className="col-sm-7"
          style={{
            display: 'flex',
            flexDirection: 'column',
            // display: 'flex',
            // flexDirection: 'column',
            // justifyContent: 'center',
            // alignItems: 'center',
            // height: '100%',
            // width: '100%',
            // margin: 'auto',
            // position: 'absolute',
          }}
        >
          <div style={{ marginBottom: 25, textAlign: 'left', justifyContent: 'left' }}>
            <h5>Acesse sua conta</h5>
            <span>Faça login para continuar</span>
          </div>

          <Form>
            <FormGroup>
              <Label
                style={{
                  fontWeight: 'bold',
                  color: errorCPF?.length > 1 ? '#E44B39' : '#2C2C2D',
                }}
              >
                CPF
              </Label>
              <Input
                style={{ borderColor: errorCPF?.length > 1 ? '#E44B39' : '#DEE2E6' }}
                id="exampleEmail"
                name="email"
                value={cpf}
                placeholder="000.000.000-00"
                type="text"
                onChange={(e) => {
                  setCPF(cpfMask(e.target.value));
                }}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onKeyPress={async (e) => {
                  if (e.charCode === 13) {
                    if (!loading) {
                      await handleSubmitLogin();
                    }
                  }
                }}
              />
              <text
                style={{
                  fontSize: 14,
                  color: '#E44B39',
                  marginBottom: 5,
                }}
              >
                {errorCPF}
              </text>
            </FormGroup>
            <FormGroup>
              <Label
                style={{
                  fontWeight: 'bold',
                  color: errorPassword.length > 1 ? '#E44B39' : '#2C2C2D',
                }}
              >
                Senha
              </Label>
              <InputGroup>
                <Input
                  style={{
                    borderColor: errorPassword.length > 1 ? '#E44B39' : '#DEE2E6',
                  }}
                  id="examplePassword"
                  name="password"
                  placeholder="Insira sua senha"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }} // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onKeyPress={async (e) => {
                    if (e.charCode === 13 && !loading) {
                      await handleSubmitLogin();
                    }
                  }}
                />
                <InputGroupText
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </InputGroupText>
              </InputGroup>
              <text
                style={{
                  fontSize: 14,
                  color: '#E44B39',
                  marginBottom: 5,
                }}
              >
                {errorPassword}
              </text>
            </FormGroup>
            <div style={{ display: 'flex', justifyContent: 'end', marginBottom: 15 }}>
              <a style={{ textDecoration: 'none' }} href="/forgotPassword">
                Esqueceu a senha?
              </a>
            </div>
          </Form>
          <Button
            disabled={loading}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={async () => {
              await handleSubmitLogin();
            }}
            style={{ maxWidth: '100%' }}
            color="success"
            size="md"
          >
            {loading ? <Spinner type="border" size={'sm'} color="light" /> : 'Entrar'}
          </Button>
          <hr />
          <div
            style={{
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <p style={{ fontSize: '9', fontWeight: 500 }}>
              Primeiro acesso?{' '}
              <a style={{ textDecoration: 'none' }} href="/register">
                Crie uma conta
              </a>
            </p>
          </div>

          {/* <hr />
          <Button
            // onClick={handleSubmitLogin}
            style={{ width: '100%' }}
            color="primary"
            size="md"
          >
            Entrar com <b style={{ fontSize: 18 }}>gov.br</b>
          </Button> */}
        </div>
      </div>
    </div>
  );
};
