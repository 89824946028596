import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { FormGroup, Form, Input, Label, Button, Spinner } from 'reactstrap';
import { Container } from './style';
import { api } from './../../services/api';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

export const ForgotPassword = (): JSX.Element => {
  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [loading, setLoading] = useState(false);
  // const { signIn, loading } = useAuth();
  async function handleSubmitLogin(): Promise<void> {
    if (email.length < 1) {
      setErrorEmail('Email obrigatório.');
    } else if (!email.includes('@')) {
      setErrorEmail('É necessário um email válido.');
    } else if (email.split('@')[1] === '') {
      setErrorEmail('É necessário um email válido.');
    } else {
      setErrorEmail('');
    }
    // if (cpf.length === 14 && password !== '' && password.length >= 8) {
    if (email !== '') {
      // localStorage.setItem('inputValueCpf', cpf);
      // localStorage.setItem('inputValuePassword', password);
      await sendEmail();
    }
  }
  // useEffect(() => {
  //   setCPF(localStorage.getItem('inputValueCpf') as unknown as string);
  //   setPassword(localStorage.getItem('inputValuePassword') as unknown as string);
  // }, []);

  async function sendEmail(): Promise<void> {
    try {
      setLoading(true);
      const resetPassword = await api.post('auth/forgot-password', {
        email,
      });
      toast('Email enviado!', { type: 'success' });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // @ts-expect-error error
      if (error?.response?.status === 400) {
        toast('Email não encontrado!', { type: 'error' });
        console.log(error);
      } else {
        toast('Um erro foi encontrado, contate o administrador do sistema, ou tente novamente', {
          type: 'error',
        });
        console.log(error);
      }
    }
  }

  return (
    <div style={{ backgroundColor: '#FFF', margin: 'auto' }}>
      <Container>
        <div className="login-form">
          <div
            style={{
              justifyContent: 'center',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
                margin: 'auto',
                position: 'absolute',
              }}
            >
              <div className="content">
                <Form style={{ justifyContent: 'center', alignItems: 'center' }}>
                  <FormGroup>
                    <p style={{ textAlign: 'center', fontSize: 17, width: '100%' }}>
                      Informe seu email cadastrado no sistema para
                      <br /> enviarmos as instruções de redefinição de senha.
                    </p>
                  </FormGroup>
                  <FormGroup>
                    <Label
                      style={{
                        fontWeight: 'bold',
                        color: errorEmail?.length > 1 ? '#E44B39' : '#2C2C2D',
                      }}
                    >
                      Email
                    </Label>
                    <Input
                      style={{ borderColor: errorEmail?.length > 1 ? '#E44B39' : '#DEE2E6' }}
                      id="exampleEmail"
                      name="email"
                      value={email}
                      placeholder="Insira seu email"
                      type="text"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                      onKeyPress={async (e) => {
                        if (e.charCode === 13) {
                          if (!loading) {
                            await handleSubmitLogin();
                          }
                        }
                      }}
                    />
                    <text
                      style={{
                        fontSize: 14,
                        color: '#E44B39',
                        marginBottom: 5,
                      }}
                    >
                      {errorEmail}
                    </text>
                  </FormGroup>
                  <FormGroup
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 15,
                    }}
                  >
                    <Button
                      disabled={loading}
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                      onClick={async () => {
                        await handleSubmitLogin();
                      }}
                      style={{ width: '100%' }}
                      color="success"
                      size="md"
                    >
                      {loading ? <Spinner type="border" size={'sm'} color="light" /> : 'Enviar'}
                    </Button>

                    <div style={{ display: 'flex', justifyContent: 'end', marginBottom: 15 }}>
                      <a style={{ textDecoration: 'none' }} href="/login">
                        Voltar ao login
                      </a>
                    </div>
                  </FormGroup>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
