import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Dropdown,
  Input,
} from 'reactstrap';
import { FaPlus } from 'react-icons/fa';
import { CalendarLocal } from '../components/CalendarLocal';
import { ModalAddEvent } from '../components/modalAddEvent/index';
import { useEvent } from './../../hooks/useEvent';
import Loading from 'react-loading';
import { FilterYear } from '../components/filterYear';
import { Row, Icon } from './style';
import 'react-calendar/dist/Calendar.css';

export function Schedule(): JSX.Element {
  const { loading, getAll, setCurrentYear, currentYear } = useEvent();
  const [isOpenModalRound, setIsOpenModalRound] = useState(false);

  useEffect(() => {
    void getAll(currentYear);
  }, [currentYear]);

  const toggleModal = (): void => {
    setIsOpenModalRound((prev) => !prev);
  };

  const calendarEntries = {
    '2023': [
      { month: 8, label: 'Setembro, 2023', indexDaysVisibles: 4, greater: true },
      { month: 9, label: 'Outubro, 2023', indexDaysVisibles: 3, greater: false },
    ],
    '2024': [
      { month: 7, label: 'Agosto, 2024', indexDaysVisibles: 4, greater: true },
      { month: 8, label: 'Setembro, 2024', indexDaysVisibles: 3, greater: false },
    ],
    '2025': [
      { month: 7, label: 'Agosto, 2025', indexDaysVisibles: 4, greater: true },
      { month: 8, label: 'Outubro, 2025', indexDaysVisibles: 3, greater: false },
    ],
  };

  return (
    <div style={{ width: '100%', backgroundColor: '#FFF' }}>
      {loading ? (
        <div
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 25 }}
        >
          <Loading width={30} color={'#005675'} type={'spin'} />
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              margin: 25,
              display: 'flex',
              justifyContent: currentYear === '2024' ? 'space-between' : 'flex-end',
              height: 60,
            }}
          >
            {Number(currentYear) === new Date().getUTCFullYear() && (
              <Icon style={{ width: 200 }} onClick={toggleModal} color="primary">
                <span>
                  <FaPlus size={14} /> Adicionar evento
                </span>
              </Icon>
            )}
          </div>

          {calendarEntries[currentYear]?.map(({ month, label, indexDaysVisibles, greater }) => (
            <div key={label} style={{ margin: 25 }}>
              <h5>
                <b>{label}</b>
              </h5>
              <CalendarLocal
                year={parseInt(currentYear, 10)}
                month={month}
                indexDaysVisibles={indexDaysVisibles}
                greater={greater}
              />
            </div>
          ))}
        </div>
      )}

      {isOpenModalRound && <ModalAddEvent isOpen={isOpenModalRound} handleIsOpen={toggleModal} />}
    </div>
  );
}
